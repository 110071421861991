import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

const url = "api/v1/rehabs";

export async function getAllRehabs() {
  const request = {
    keyword: "",
    pageNumber: 0,
    pageSize: 100,
    orderBy: ["id"],
  };
  return await ApiService.post(url + "/search", request as never).then(
    (res) => {
      const items = res.data.data;
      //TODO DEBT
      items.forEach((item: any) => {
        item.effectiveDate = new Date(item.effectiveDate);
        item.termDate = new Date(item.effectiveDate);
      });

      return items;
    }
  );
}

export async function getRehab(rehabId) {
  return await ApiService.get(url + "/" + rehabId)
    .then((res) => {
      const rehab = res.data;
      return rehab;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function updateArchiveOlderThanDays(rehabId,archiveOlderThanDays: any) {
  return await ApiService.post(url + "/updateArchiveOlderThanDays", {ArchiveOlderThanDays:archiveOlderThanDays, Id:rehabId } as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}


